@use '../../utils/mixins';
@use '../../utils/colors';

@use './eventRegistrationContainer';
@use './eventRegistrationNav';
@use './guestManagement';
@use './registrationOptionsForm';
@use './eventRegCheckpoint';
@use './constituentSearch';
@use './registrantListTray';
@use './eventRegistrationFooter';
@use './eventRegDetailsCard';
@use './reviewRegistration';
@use './removeEventModal';
@use './additionalInfo';
@use './eventRegistrationContactInfo';
@use './eventConfirmation';
@use './notInvited';

.eventRegUserIdentification__identifiedWrapper {
  text-align: center;
}

.eventRegUserIdentification__notYouButton {
  margin-top: 6.4rem;
}

.eventRegistrantName {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.eventRegistrantName__icon--light {
  color: colors.$color-gray-3;
}

.eventReg__yesNoButtons {
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.eventReg__yesNoButton {
  flex-direction: column;
  padding: 2.4rem 4rem;

  font-size: 1.8rem;
  color: colors.$color-black;

  &:disabled {
    display: flex;
  }

  .button--iconOnly--secondary {
    width: 4rem;
    height: 4rem;

    font-size: 1.8rem;
  }
}

.eventRegistrationAlert__buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
}

.eventRegistrationAlert__alreadyRegisteredList {
  padding: 0;

  list-style-type: none;
}

.eventRegistrationAlert__alreadyRegisteredList
  + .eventRegistrationAlert__buttonWrapper {
  margin-top: 2.4rem;
}

.eventRegistrationAlert__alreadyRegisteredListItem
  + .eventRegistrationAlert__alreadyRegisteredListItem {
  margin-top: 1.6rem;
}
