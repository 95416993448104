@use '../utils/mixins';
@use '../utils/colors';
@use '../utils/variables';
@use 'sass:map';

button,
.button {
  min-width: 18rem;
  border-radius: 10px;
  border: none;
  background: colors.$color-black;
  padding: 1.3rem;

  font: 700 1.8rem/1.2 variables.$font-stack-default;
  color: colors.$color-white;
  text-decoration: none;
  text-align: center;

  transition: background 0.25s ease-in-out, color 0.25s ease-in-out;

  &:hover {
    filter: drop-shadow(map.get(variables.$elevation-shadows, 'hover'));

    cursor: pointer;
  }

  &:disabled {
    background: colors.$color-gray-3;

    &:hover {
      filter: none;

      cursor: not-allowed;
    }
  }
}

.button--secondary {
  @extend .button;
  border: solid 2px colors.$color-black;
  background: colors.$color-white;

  color: colors.$color-black;
  text-decoration: none;

  &:disabled {
    border-color: colors.$color-gray-3;
    background: colors.$color-white;

    color: colors.$color-gray-3;
  }
}

.button--tertiary {
  @extend .button;
  border-radius: 15px;
  border: solid 2px colors.$color-gray-2;
  background: colors.$color-white;

  color: colors.$color-gray-5;
}

.button--underline {
  @extend .button;
  min-width: auto;
  border-radius: unset;
  background: none;
  padding: unset;

  font: 500 1.4rem/1.2 variables.$font-stack-default;
  color: colors.$color-gray-4;
  text-decoration: underline;

  &:hover {
    filter: none;
  }
}

.button--withIcon {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: unset;
  border-radius: 10px;
  border: solid 2px colors.$color-gray-2;
  background: colors.$color-white;

  font: 700 1.4rem/1.2 variables.$font-stack-default;
  color: colors.$color-gray-5;
  text-align: left;

  &:disabled {
    display: block;
    text-align: center;
  }
}

.button--iconOnly--primary {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: unset;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: solid 2px colors.$color-gray-2;
  background: colors.$color-white;

  color: colors.$color-gray-5;
  font-size: 2.4rem;
  text-align: center;
}

.button--iconOnly--secondary {
  @extend .button--iconOnly--primary;
  width: 2.8rem;
  height: 2.8rem;
  border: solid 2px colors.$color-white;
  background: colors.$color-gray-3;

  font-size: 1.3rem;
}

.button--iconOnly--tertiary {
  min-width: unset;
  border-radius: unset;
  background: unset;
  padding: 0;

  font-size: 2.4rem;
  color: colors.$color-gray-5;

  &:hover {
    filter: none;
  }
}

.button--centered {
  display: block;
  margin: 0 auto;
}
