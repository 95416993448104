@use '../../utils/colors';
@use '../../utils/mixins';

.multiLevelEventDetails__subEventsWrapper {
  margin-top: 4rem;
  border-top: solid 1px colors.$color-gray-3;
  padding-top: 2.4rem;
}

.multiLevelEventDetails__subEventsList {
  margin: 0;
  padding: 0;
}

.multiLevelEventDetails__subEventsAccordionContent {
  padding: 1.6rem;

  @include mixins.medium {
    padding: 2.4rem;
  }
}

.subEventDetailCard {
  display: block;
  max-width: unset;
  border: solid 2px colors.$color-gray-2;
  border-radius: 8px;
  padding: 1.6rem;

  list-style-type: none;

  transition: border-color 0.1s ease-in-out;

  @include mixins.medium {
    padding: 2.4rem 4rem 2.4rem 1.6rem;
  }

  &--selected {
    border-color: colors.$color-black;
  }

  & + & {
    margin-top: 4rem;
  }

  .checkboxLabel,
  .checkboxInput:checked + .checkboxLabel {
    font-weight: 700;
  }
}

.multiLevelEventDetails__subEventsGrouping + .subEventDetailCard,
.subEventDetailCard + .multiLevelEventDetails__subEventsGrouping {
  margin-top: 4rem;
}

.subEventDetailCard__eventTitle {
  margin: 0;

  @include mixins.medium {
    padding-left: 4.4rem;
  }
}

.checkbox + .subEventDetailCard__details,
.subEventDetailCard__eventTitle + .subEventDetailCard__details,
.subEventDetailCard__details + .subEventDetailCard__rsvpWrapper {
  margin-top: 1.6rem;
}

.subEventDetailCard__details {
  @include mixins.medium {
    padding-left: 4.4rem;
  }
}

.subEventDetailCard__detailsMiddle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;

  .eventAlert__wrapper {
    flex-direction: row;
  }
}

.subEventDetailCard__location {
  margin: 0;
}

.subEventDetailCard__cost {
  margin: 0;

  color: colors.$color-black;

  svg {
    display: none;
  }
}

.subEventDetailCard__description {
  display: none;

  @include mixins.medium {
    position: relative;
    display: block;
    margin-top: 1.6rem;
    max-height: 7.2rem;

    overflow: hidden;

    // Overlay a fade gradient to show the content is hidden.
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2.4rem;
      background: linear-gradient(
        to bottom,
        rgba(colors.$color-white, 0.25) 0%,
        rgba(colors.$color-white, 1) 100%
      );
    }
  }
}

.subEventDetailCard__detailsMiddle + .subEventDetailCard__viewMore {
  margin-top: 1.6rem;
}

.subEventDetailCard__viewMore {
  display: block;

  @include mixins.medium {
    flex: 0;
    align-self: flex-end;
    display: inline-block;
  }
}

.subEventDetailCard__rsvpWrapper {
  display: flex;
  gap: 2.4rem;
  justify-content: space-between;

  @include mixins.medium {
    justify-content: flex-end;
  }
}

.subEventDetailCard__rsvpButton {
  min-width: unset;
}

.subEventDetailCard__rsvpStatus {
  max-width: unset;
  text-align: right;
}

.multiLevelEventDetails__eventsSelected {
  border-radius: 8px;
  padding: 2.4rem;
  background-color: colors.$color-ghost-white;
  display: flex;
  justify-content: center;

  & > div {
    flex-basis: content;
  }
}

.multiLevelEventDetails__eventsSelectedTitle {
  margin: 0;
}

.multiLevelEventDetails__eventsSelectedTitle
  + .multiLevelEventDetails__eventsSelectedList {
  margin-top: 1.6rem;
}

.multiLevelEventDetails__eventsSelectedList {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.multiLevelEventDetails__eventsSelectedItem {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  color: colors.$color-black;

  & + & {
    margin-top: 0.8rem;
  }
}

.multiLevelEventDetails__subEventsList + .form__overallError,
.multiLevelEventDetails__subEventsList
  + .multiLevelEventDetails__eventsSelected,
.form__overallError + .multiLevelEventDetails__backContinue,
.multiLevelEventDetails__eventsSelected + .multiLevelEventDetails__backContinue,
.multiLevelEventDetails__subEventsList + .multiLevelEventDetails__backContinue {
  margin-top: 5.6rem;
}

.multiLevelEventDetails__backContinue {
  display: flex;
  gap: 1.6rem;
  flex-direction: column-reverse;

  @include mixins.medium {
    justify-content: space-between;
    flex-direction: row;
  }
}

.multiLevelEventDetailsFooter__right {
  @include mixins.medium {
    margin-left: auto;
  }
}

.multiLevelEventDetails__continue {
  width: 100%;

  @include mixins.medium {
    width: auto;
  }
}
